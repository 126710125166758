import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  APPConfigAction,
  selectCurrentBusinessMode,
} from "../../app/Reducers/AppConfig";
import { EBusinessType } from "../../app/Reducers/AppConfig/types";
import ProcessingLoading from "../../components/Loader/Processing";
import { useOpenState } from "../../hooks/useOpenState";
import ConnectionsService from "../../utils/Services/Connections";
import UserService from "../../utils/Services/User";
import { toastifyGeneralTheme } from "../../utils/ToastifyGeneral";

interface OnboardingContextType {
  isOpen: { [key: string]: boolean };
  userConnections: UserConnectionsInterface[];
  handleIsOpen: (key: string, value: boolean) => void;
  handleOpenClose: (key: string) => void;
  isServiceConnected: (key: string) => boolean;
  handleFinishTutorial: () => void;
  checkServices: (services: string[]) => boolean;
}
interface UserConnectionsInterface {
  service: string;
  isConnected: boolean;
}

const OnboardingContext = createContext<OnboardingContextType | null>(null);

export const useOnboardingContext = () => {
  const context = useContext(OnboardingContext);
  if (!context) {
    throw new Error(
      "useOnboardingContext must be used within an OnboardingProvider",
    );
  }
  return context;
};
export const onboardingPages = {
  [EBusinessType.POD]: "/onboarding/pod",
  [EBusinessType.BRANDED]: "/onboarding/branded",
  [EBusinessType.DROPSHIPPING]: "/onboarding/dropshipping",
};
const OnboardingInitial: React.FC = () => {
  const [isOpen, handleIsOpen, handleOpenClose] = useOpenState({
    printify: false,
    shopify: false,
    isLoading: false,
  });
  const currentBusinessType = useSelector(selectCurrentBusinessMode);
  const navigate = useNavigate();
  const userService = new UserService();
  const connectionService = new ConnectionsService();
  const [userConnections, setUserConnections] = useState<
    UserConnectionsInterface[]
  >([]);
  const dispatch = useDispatch();
  const handleChangeBusinessType = (businessType: EBusinessType) => {
    dispatch(APPConfigAction.changeMode(businessType));
  };
  useEffect(() => {
    if (currentBusinessType !== null) {
      navigate(onboardingPages[currentBusinessType]);
    }
  }, [currentBusinessType]);
  useEffect(() => {
    const fetchData = () => {
      connectionService
        .get_data(``)
        .then((res) => setUserConnections(res.services))
        .catch((err) =>
          toast.error(`Failed to get the connection statuses`, {
            ...toastifyGeneralTheme,
          }),
        )
        .finally(() => handleIsOpen("isLoading", false));
    };

    // Call the fetchData immediately on mount
    fetchData();

    // Set up the interval to call fetchData every 10 seconds
    const intervalId = setInterval(fetchData, 10000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const markTutorialAsViewed = () => {
    const user = JSON.parse(localStorage.getItem("user") as any);
    if (user) {
      user.hasViewedTutorial = true;
      localStorage.setItem("user", JSON.stringify(user));
    }
  };
  const isServiceConnected = (serviceName: string): boolean => {
    const service = userConnections.find(
      (connection) => connection.service === serviceName,
    );
    return service ? service.isConnected : false;
  };

  const handleFinishTutorial = () => {
    handleIsOpen("isLoading", true);
    userService
      .put_data("", { hasViewedTutorial: true })
      .then(() => {
        markTutorialAsViewed();
        window.location.replace("/");
      })
      .catch((err) =>
        toast.error(`Failed to end the tutorial`, {
          ...toastifyGeneralTheme,
        }),
      )
      .finally(() => handleIsOpen("isLoading", false));
  };

  function checkServices(servicesRequired: string[]): boolean {
    if (userConnections.length === 0) return true;
    for (const service of userConnections) {
      // service.service === "shopify" ||
      if (servicesRequired.includes(service.service) && !service.isConnected) {
        return true;
      }
    }
    return false;
  }
  const contextValue: OnboardingContextType = {
    isOpen,
    handleIsOpen,
    handleOpenClose,
    isServiceConnected,
    handleFinishTutorial,
    userConnections,
    checkServices,
  };

  return (
    <OnboardingContext.Provider value={contextValue}>
      <ProcessingLoading isLoading={isOpen.isLoading} />
      <Outlet />
    </OnboardingContext.Provider>
  );
};

export default OnboardingInitial;
