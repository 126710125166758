import { useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import { selectCurrentBusinessMode } from "../app/Reducers/AppConfig";
import BusinessSelection from "./BusinessSelection";
import "./index.css";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import TutorialContainer from "./TutorialContainer";

const locationsToHide = [
  "/storeBuilder",
  "/createProduct",
  "/onboarding",
  "/onboarding/pod",
  "/onboarding/branded",
  "/onboarding/dropshipping",
  "/dropshipping/create",
  "/pod/create",
  "/branded/create",
  "/editor",
];

const Layout = () => {
  const currentBusinessType = useSelector(selectCurrentBusinessMode);
  const currentPath = useLocation().pathname;
  const isInDev = locationsToHide.includes(currentPath);

  if (!currentBusinessType) return <BusinessSelection />;
  return (
    <div
      className="bg-gray dark:layout-custom-shadow  flex h-screen overflow-y-auto
     bg-gray-100 duration-500 dark:bg-main-blue-900"
    >
      {/* <div className=" mb-0 mr-4 border-solid border-r border-green-500 rounded-tr-[30px] bg-white"> */}
      <div className={`${isInDev ? "hidden" : ""}`}>
        <Sidebar />
      </div>
      <div className="flex w-full flex-col ">
        {/* <div className=" mb-2  border-solid border-green-500 border-b rounded-bl-[15px] bg-white border-l"> */}
        <div className={`${isInDev ? "hidden" : ""}`}>
          <Navbar />
        </div>
        <div
          className={` drag-limit ${
            isInDev ? "h-full" : "height-adjust"
          }  flex  flex-row`}
        >
          <div
            id="main-content"
            className="bg-dark-gray w-full flex-grow overflow-y-auto
           bg-white dark:bg-main-gray-layer"
            // className=" mb-0 border-l border-t bg-white p-2 overflow-y-auto flex-grow bg-gray-100 rounded-tl-[30px] border-solid border-green-500"
          >
            <Outlet />
          </div>
          <TutorialContainer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
