import { useDispatch } from "react-redux";
import { APPConfigAction } from "../../app/Reducers/AppConfig";
import { EBusinessType } from "../../app/Reducers/AppConfig/types";
import { CustomNotFilledNormalButton } from "../../components/v2/Button";
import SelectBusinessCard from "./components/SelectBusinessCard";

const BusinessSelection = () => {
  const dispatch = useDispatch();
  const handleChangeBusinessType = (businessType: EBusinessType) => {
    dispatch(APPConfigAction.changeMode(businessType));
  };
  const businessTypes = [
    {
      title: "Print on Demand (POD)",
      description:
        "Customize and sell products without holding any inventory. We handle the printing and shipping for you.",
      src: "assets/businessType/pod.svg",
      onClick: () => handleChangeBusinessType(EBusinessType.POD),
    },
    {
      title: "Drop shipping",
      description:
        "Sell products from suppliers who ship directly to your customers, eliminating the need for inventory management.",
      src: "assets/businessType/dropshipping.svg",
      onClick: () => handleChangeBusinessType(EBusinessType.DROPSHIPPING),
    },
    {
      title: "Branded business ",
      description:
        "Customize and sell products without holding any inventory. We handle the printing and shipping for you.",
      src: "assets/businessType/branded.svg",
      onClick: () => handleChangeBusinessType(EBusinessType.BRANDED),
    },
  ];
  return (
    <div className="flex h-screen w-full items-center justify-center bg-[#A793F6]  bg-gradient-to-l from-[#f7f4ff] to-[#c4aeff] ">
      <CustomNotFilledNormalButton
        className="absolute right-5 top-5 w-[120px] border-0 text-black"
        iconPath={"/assets/sidebar/dropdown/logout.svg"}
        title="Sign Out"
        label="Sign Out"
        onClick={() => window.location.replace("/login")}
      />
      <div className="justify-top flex flex-col items-center gap-[35px] rounded-[40px] bg-white px-[80px] py-[40px] lg:h-[650px] lg:w-[1200px]">
        <div className="flex flex-col items-center gap-2">
          <img
            className="h-[40px] w-[40px]"
            alt="logo"
            src="assets/logo/main.svg"
          ></img>
          <div className="font-['Outfit'] text-[32px] font-medium leading-10 text-[#1c1a22]">
            Welcome to Launchify!
          </div>
          <div className="text-center font-['Outfit'] text-base font-normal leading-tight text-[#48454e]">
            We’re excited to help you kickstart your online business journey. To
            begin, please select your preferred business model for this time
          </div>
        </div>
        <div className="flex w-full flex-row items-center justify-center gap-[20px]">
          {businessTypes.map(({ title, src, description, onClick }, index) => {
            return (
              <SelectBusinessCard
                key={index}
                src={src}
                title={title}
                description={description}
                onClick={onClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BusinessSelection;
