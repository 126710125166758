import React, { useState, useCallback } from "react";

export type OpenState = {
  [key: string]: boolean;
};

export const useOpenState = (
  initialState: OpenState,
): [
  isOpen: OpenState,
  handleIsOpen: (name: string, value: boolean) => void,
  handleOpenClose: (name: string) => void,
  isAnyTrue: boolean,
  isAnyFalse: boolean,
] => {
  const [isOpen, setIsOpen] = useState<OpenState>(initialState);

  const handleIsOpen = useCallback((name: string, value: boolean) => {
    setIsOpen((prev) => ({ ...prev, [name]: value }));
  }, []);
  const handleOpenClose = useCallback((name: string) => {
    setIsOpen((prev) => ({ ...prev, [name]: !prev[name] }));
  }, []);
  function hasBoolValue(obj: OpenState, bool: boolean) {
    for (const key in obj) {
      if (isOpen.hasOwnProperty(key) && obj[key] === bool) {
        return true;
      }
    }
    return false;
  }
  const isAnyTrue = useCallback(() => {
    return hasBoolValue(isOpen, true);
  }, [isOpen])();
  const isAnyFalse = useCallback(() => {
    return hasBoolValue(isOpen, false);
  }, [isOpen])();

  return [isOpen, handleIsOpen, handleOpenClose, isAnyTrue, isAnyFalse];
};
