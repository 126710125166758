import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
// import { Epic, ofType } from "redux-observable";
// import { take, tap } from "rxjs";
// import { StoreState } from '../../store';
import { LogoStageData } from "./types";
import { StoreState } from "../../Types";

export const STAGE_PREFIX = "LOGO_STAGE";

export const logoStageDataEntity = createEntityAdapter<LogoStageData>();

export const logoStageDataSlice = createSlice({
  name: STAGE_PREFIX,
  initialState: logoStageDataEntity.setAll(
    logoStageDataEntity.getInitialState(),
    [],
  ),
  reducers: {
    addItem(state, action) {
      if (Array.isArray(action.payload)) {
        logoStageDataEntity.addMany(state, action.payload);
        return;
      }
      logoStageDataEntity.addOne(state, action.payload);
    },
    updateItem(state, action: PayloadAction<LogoStageData | LogoStageData[]>) {
      if (Array.isArray(action.payload)) {
        logoStageDataEntity.updateMany(
          state,
          action.payload.map((item) => ({
            id: item.id,
            changes: item.attrs,
          })),
        );
        return;
      }
      logoStageDataEntity.updateOne(state, {
        id: action.payload.id,
        changes: action.payload,
      });
    },

    removeItem(state, action) {
      if (Array.isArray(action.payload)) {
        logoStageDataEntity.removeMany(state, action.payload);
        return;
      }
      logoStageDataEntity.removeOne(state, action.payload);
    },
    clearItems(state, action) {
      logoStageDataEntity.removeAll(state);
    },
    // getObjectById(state, id) {return state.objects[id]};
  },
});

const logoStageDataReducer = logoStageDataSlice.reducer;

export const logoStageDataSelector = logoStageDataEntity.getSelectors(
  (state: StoreState) => state.logoStageData,
);
export const logoStageDataAction = logoStageDataSlice.actions;

export default logoStageDataReducer;
